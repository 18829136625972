/* body{
  background: url('../public/Main Page.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0;
  
} */
.bg{
  background: url('../public/Main Page.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover; 
   margin-top: 0px; 
   margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  
} 
.nav-bar {
    /* margin-left: 20px; */
    margin-right: 20px;
    display: flex;
    /* margin: 30px 20px; */
}

.logo-hamburger{
  flex-grow: 2;
  margin-top: 20px;
}

.logo {
  flex-grow: 2;
}

.hamburger{
  display: none;
}

.links{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.07em;
  color: #FFFFFF;
  flex-grow: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* .drop-down{
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: none;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.07em;
  color: #FFFFFF;
  flex-grow: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.drop-down{
  margin-top: 25px;
  position: absolute;
  overflow: auto;
  display: flex;
  flex-direction: column;
  display: none;
  
}

.drop-down-element{
  border: 1px solid white;
  padding: 10px 10px;
}

a:link {
  color: #FFFFFF;
  text-decoration: none;
}

a:visited {
  color: #FFFFFF;
  text-decoration: none;
}

.link-box {
  border-radius: 12%;
  transition: all;
  transition-duration: 0.8s;
  transition-timing-function: ease-in;
  padding: 10px 10px;
}

.link-box:hover {
  border: 2px solid rgba(255, 174, 223, 0.866406);
  background: rgba(255, 255, 255, 0.05);
}


.main{
  margin-left: 20px;
  margin-right: 20px;
  margin: 50px 10px;
  display: flex;
  justify-content: space-evenly;
}

.main-text{
  /* display: flex;
  flex-direction: column; */
}

#intro p{
  width: 550px;
  /* height: 200px; */
   
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 68px;
  background: linear-gradient(180deg, #5957D2 0%, rgba(255, 174, 223, 0.866406) 32.28%, rgba(228, 228, 228, 0.732542) 47.06%, rgba(89, 87, 210, 0.55) 65.2%, rgba(255, 174, 223, 0.413452) 79.9%, #FFFFFF 101.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

#main-para p{
  width: 500px;
  /* height: 90px; */
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.8;
}

#hashTag{
  color: #FFBC0F;
  cursor: pointer;
  background-color: rgb(71, 71, 71);
}

.main-buttons{
  margin: 10px 0px;
  padding: 10px 0px;
  display: flex;
  justify-content: space-around;
  margin-top: 65px;
}

.btns{
  display: block;
}




button {
  width: 125px;
  height: 40px;
  background: #333333;
  box-shadow: 12px 12px 24px #1E1E1E;
  transition-property: position;
  transition-duration: 2s;
  border: 0px;
  border-radius: 10px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  letter-spacing: 0.02em;

  color: #FFFFFF;
}

button:hover{
  position: relative;
  bottom: 5px;
}

.main-img-box{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.main-img{
  width: 400px;
  
}

/* .buttons{
  display: flex;
  justify-content: space-around;
} */

.cards-section{

  margin: 50px 10px;
  margin-left: 20px;
  margin-right: 20px;

}

.card-heading .card-para{
  text-align: center;
  font-family: 'Poppins';
  font-weight: 550px;
  font-style: normal;
  font-size: 40px;
  background: linear-gradient(180deg, #5957D2 0%, rgba(255, 174, 223, 0.866406) 82.5%, rgba(228, 228, 228, 0.732542) 133.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.cards {

  margin: 50px 10px;
  padding: 40px 40px;
  overflow-x: auto;
  display: flex;
  justify-content: space-between;
}

.card-content {
  position: relative;
  bottom: 40px;
  
}


.card {
  margin-top: 40px;
  box-sizing: border-box;
  min-width: 370px;
  height: 440px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 12px 12px 24px #1E1E1E;
  margin-left: 30px;
  margin-right: 30px;
  padding: 10px 10px;
}

.card-imgs {
  width: 145px;
  height: 155px;
  display: block;
  margin: auto;
  filter: drop-shadow(12px 12px 24px #2d2d2d);
}

.cards::-webkit-scrollbar {
  width: 6px;
}

.cards::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #424072 0%;
}


.cards::-webkit-scrollbar-thumb {
  background: rgba(209, 134, 178, 0.866) 82.5%;
  border-radius: 6px;
}

.card-writeUp-head {
  height: 90px;
  /* width: 300px; */
  text-align: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  background: linear-gradient(180deg, #706DFF 0%, rgba(236, 122, 224, 0.81) 52.87%, rgba(255, 255, 255, 0.81) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: auto;
}

.card-writeUp-desc {
  /* height: 90px; */



  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  /* or 170% */

  text-align: center;
  letter-spacing: 0.03em;

  color: #FFFFFF;
  height: 130px;
  opacity: 0.5;
  margin: auto;
}

.card-btn {
  display: block;
  margin: auto;
  margin-top: 20px;
  width: 125px;
  height: 40px;
  background: #333333;
  box-shadow: 12px 12px 24px #1E1E1E;
  transition-property: position;
  transition-duration: 2s;
  border: 0px;
  border-radius: 10px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  letter-spacing: 0.02em;

  color: #FFFFFF;
}



.footer-terms {
  text-align: center;
  
  

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  

  margin-bottom: 0;
  letter-spacing: 0.03em;

  color: #FFFFFF;

  opacity: 0.5;
}

.events{
  margin-left: 20px;
  margin-right: 20px;
}

.event-cards{
  margin: 50px 0;
  display: flex;
  overflow-x: auto;
  
}

.event-card {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 12px 12px 24px #1E1E1E;
  border-radius: 43px;
  border: 2px solid rgba(236, 122, 224, 0.);
  z-index: 0;
  overflow-x: auto;
  min-width: 440px;
  margin-left: 40px;
  margin-right: 40px;
}


.event-para {
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 62px;
  text-align: center;
  background: linear-gradient(180deg, #706DFF 0%, rgba(236, 122, 224, 0.81) 20.31%, #15BFFD 58.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}



.card-head {
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  background: linear-gradient(180deg, #706DFF 0%, rgba(236, 122, 224, 0.81) 20.31%, #15BFFD 58.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}





.event-cards::-webkit-scrollbar {
  width: 6px;
}

.event-cards::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #424072 0%;
}


.event-cards::-webkit-scrollbar-thumb {
  background: rgba(209, 134, 178, 0.866) 82.5%;
  border-radius: 6px;
}




.event-sec {
  margin: 20px 20px;
}

.event-des {
  margin: 20px 30px;
  padding: 20px 20px;
  min-width: 366px;
  height: 222px;
  background: rgba(240, 239, 239, 0.03);
  border-radius: 39px;
  min-height: 290px;
}



.event-des-head {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;

  background: linear-gradient(180deg, #15BFFD 0%, rgba(236, 122, 224, 0.81) 28.65%, rgba(255, 255, 255, 0.81) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 54px;
}

.event-des-para {


  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;

  text-align: left;

  letter-spacing: 0.14em;

  color: #FFFFFF;
  min-height: 150px;
  opacity: 0.55;
}

.event-des-para-span {
  text-decoration: underline;
}

.twitter-box {
  width: 121px;
  height: 35px;
  border-radius: 25px;
  background: rgba(217, 217, 217, 0.34);

  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.twitter-logo {
  display: block;
  margin: auto;
}

.event-img1 {
  /* background: url('./eec5544a-5b8c-4380-bb80-c11339065b94\ 1.png'); */
  /* background: #1E1E1E; */
  background: url('../public/pic-removebg-preview.png') no-repeat center center ;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  z-index: 1;
  height: 223px;
}

.event-img2{
  background: url('../public/event-2.png') no-repeat center center ;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  z-index: 1;
  height: 223px;
}

.event-img3{
  background: url('../public/event-3.png') no-repeat center center ;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  z-index: 1;
  height: 223px;
}

.dm {

  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;

  color: #FFFFFF;
}

.date-box {

  text-align: center;
  position: relative;
  top: 20px;
  left: 330px;
  width: 80px;
  height: 80px;
  background: linear-gradient(180deg, rgba(176, 97, 252, 0.60), rgba(255, 255, 255, 0.6));

  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;

}





/* new style  */

.btn-box {
  display: flex;
  justify-content: space-evenly;
}

.event-btn{
  
  display: block;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(236, 122, 224, 1);
  border-radius: 25px;
  padding: 15px 20px;
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  /* margin-right: 100px; */
  background: linear-gradient(180deg, #706DFF 0%, rgba(236, 122, 224, 0.81) 20.31%, #15BFFD 58.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  /* box-shadow: 12px 12px 24px #1E1E1E; */
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.6s;
}


/* .event-cards {
  margin: 50px 0;
  

} */



.college-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 41px;
  line-height: 62px;
  /* identical to box height */
  letter-spacing: 0.07em;
background: linear-gradient(180deg, #5957D2 0%, rgba(255, 174, 223, 0.866406) 82.5%);
  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
}

.college-communities-head {
  text-align: center;
  margin: 50px 0px;
}

.college-communities {
  margin: 120px 0px;
}

.colleges {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: space-around;
}

.college-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 35px;
  
  letter-spacing: 0.07em;
  text-align: center;
  color: #FFFFFF;
}

.college-img{
  display: block;
  margin: auto;
  width: 300px;
  height: 100px;
}

.communities{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}
.communities_add{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;

}
.communitiess-add{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;

}
.communitiess{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
}

.community-img{
  height: 100px;
  width: 100px;
}

.pune-dao{
  width: 250px;
  height: 100px;
}

.footer{
  /* background: rgb(0,0,0);
background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8464635854341737) 40%); */
width: 100%;
/* padding: 10px 30px; */
}

.footer-display-div{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin: 10px 30px;
}
.info-side{
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
}

.follow-us-imgs{
  display: flex;
}

.footer-logo{
  height: 45px; 
  width: 40px;
}
.follow-us-img{
  margin-right: 20px;
  cursor: pointer;
  width: 25px ;
  height: 23px;
}

.move-side-text{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 26px;
/* or 170% */

text-align: center;
letter-spacing: 0.03em;

color: #FFFFFF;
position: relative;
bottom: 5px;
margin-left: 10px;
}

.useful-links-text{
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 26px;
/* or 170% */

text-align: left;
letter-spacing: 0.065em;
margin: 0 0;
color: #FFFFFF;
cursor: pointer;
}

.useful-links-head-text{
  margin-bottom: 30px;
}

.useful-links{
  display: flex;
  flex-direction: column;
}

.info-side-box{
  margin-left: 100px;
  margin-right: 100px;
}

.move-side{
  display: flex;
}



@media screen and (max-width: 1000px){
  .main{
      flex-direction: column;
  }
  .links{
    display: none;
  }
  #intro{
      margin: 10px 0;
  }
  #intro p{
      display: block;
      margin: auto;
      text-align: center;
  }
  #main-para{
      margin: 20px 0;
  }
  #main-para p{
      display: block;
      margin: auto;
      text-align: center;
      
  }
  

  /* .date-box {
      left: 250px;
  } */

  /* .event-des {
      width: 250px;
  } */

  
  .info-side-box{
      margin-left: 50px;
      margin-right: 0px;
  }
  .event-para {
      font-size: 35px;
  }
  .college-img{
      width: 200px;
      height: 80px;
  }

  .community-img{
      width: 80px;
      height: 80px;
  }
  .pune-dao{
      width: 120px;
      height: 80px;
  }
}

@media screen and (max-width: 750px){
  .event-cards {
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      overflow: hidden;
      overflow-y: auto;                           
      height: 800px;
      align-items: center;
  }
  .links{
    display: none;
    
  }
  
  .event-card{
      width: 400px;
      min-height: 600px;
  }
  .event-des {
      margin: auto;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 300px;
      /* height: 222px; */
      background: rgba(240, 239, 239, 0.03);
      border-radius: 39px;
  }

  .date-box{
      left: 300px;
  }
  .communities{
      display: flex;
      flex-direction: column;
      
      align-items: center;
  }
  .communities_add{
      display: flex;
      flex-direction: column;
      
      align-items: center;
      margin-top: -5px;
  }
  .communitiess-add{
      display: flex;
      flex-direction: column;
      
      align-items: center;
      margin-top: -5px;}
   
  .communitiess{
      display: flex;
      flex-direction: column;
      margin-top: -30px;
      align-items: center;
  }


  .community{
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px){
  .footer-logo{
      width: 30px;
      height: 35px;
  }
  .move-side-text{
      font-size: 12px;
      bottom: 8px;
  }
  .useful-links-text{
      font-size: 12px;
  }
  .links{
    display: none;
  }
  .follow-us-img{
      height: 15px;
      width: 15px;
      margin-right: 15px;
  }
  .useful-links-head-text{
      margin-bottom: 20px;
  }
  .event-para {
      font-size: 32px;
  }
}

@media screen and (max-width: 550px)
{
  .event-card{
      min-width: 300px;
     
  }
  .event-des{
      min-width: 200px;
      min-height: 330px
  }
  .exceptional-tweet-box{
      position: relative;
      top: 45px;
  }

}


@media screen and (max-width: 580px) {
  .logo-hamburger{
      display: flex;
      justify-content: space-between;
  }

  .hamburger{
      display: block;
      cursor: pointer;
      margin: auto 0 auto auto;
  }

  .hamburger .line{
      width: 25px;
      height: 2px;
      background: #fefefe;
      margin: 6px 0;
  }

  .logo-img{
      width: 65px;
  }

  .nav-bar{
      display: flex;
      flex-direction: column;

  }
  /* .links-mobile{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 0px;
      top:80px;
      left: 0;
      right: 0;
      transition: 0.5s;
      opacity: 0;
      overflow: hidden;
  }

  .links{
    display: none;
  } */

  .active{
      height: 400px;
      opacity: 1;
  }

  #intro p{
      width: 350px
  }
  #main-para p{
      width: 300px
  }
  .main-img{
      width: 350px;
  }

  .cards {
      height: 450px;
      overflow: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 20px 20px;

  }

  .card {
      /* width: 200px; */
      min-width: 0px;
      min-height: 400px;
      margin-left: 0;
      margin-right: 0;
  }

  .card-img {
      width: 110px;
      height: 120px;
  }

  .cards::-webkit-scrollbar {
      width: 2px;
  }

  .cards::-webkit-scrollbar-track {

      border-radius: 6px;
      background: #424072 0%;
  }


  .cards::-webkit-scrollbar-thumb {
      background: rgba(209, 134, 178, 0.866) 82.5%;
      border-radius: 6px;
  }

  .top-companies {
      display: flex;
      flex-direction: column;
      margin: 0;
  }
  
  .bottom-companies {
      display: flex;
      flex-direction: column;
      margin: 0;
  }
  .c-box{
      display: block;
      margin: auto;
      margin-top: 20px;
  }


}

@media screen and  (max-width:500px){
  .event-cards {
      display: flex;
      flex-direction: column;
      /* justify-content: space-around; */
  }

  .college-img{
      width: 200px;
      height: 80px;
  }

  .community-img{
      width: 80px;
      height: 80px;
  }
  .pune-dao{
      width: 120px;
      height: 80px;
  }

  /* .community-img{
      height: 100px;
      width: 100px;
      
  }

  .pune-dao{
      width: 250px;
      height: 150px;
  } */
  .footer-display-div{
      display: flex;
      flex-direction: column;
  }
  .info-side-box{
      margin-left: 0;
  }
  .footer-terms {
      font-size: 12px;
  }
  .event-para {
      font-size: 28px;
  }
}

@media screen and  (max-width:450px){
  #intro p{
      text-align: left;
      width: 300px;
  }    
  #main-para{
      text-align: left;
  }
  
  .card-writeUp-head{
      font-size: 20px;
      height: 70px;
      line-height: 30px;
  }
  .card-writeUp-desc{
      font-size: 13px;
      line-height: 24px;
      height: 150px;
  }
  
  .card-btn{
      margin-top: 10px;
  }

  .event-btn {
      font-size: 18px;
      line-height: 20px;
  }
  .date-box {
      width: 60px;
      height: 60px;
      left: 230px;
  }
  .dm{
      font-size: 15px;
      line-height: 20px;
  }

  .event-card{
      width: 320px;
  }
  .event-des{
      width: 220px;
  }

  .exceptional-tweet-box{
      top: 20px;
  }
  
  .event-des-head {
      font-size: 18px;
      line-height: 22px;
  }

  .event-des-para {
      font-size: 12px;
      line-height: 15px;
  }

  .event-img3{
      background: url('../public/event-3-final.png') no-repeat center center  ;
      -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  z-index: 1;
  }

  .college-img{
      width: 200px;
      height: 80px;
  }

  .community-img{
      width: 80px;
      height: 80px;
  }
  .pune-dao{
      width: 120px;
      height: 80px;
  }


}


@media screen and  (max-width:400px){
  #intro p{
      text-align: left;
      width: 300px;
  }    
  #main-para{
      text-align: left;
  }
  
  .card-writeUp-head{
      font-size: 20px;
      height: 70px;
      line-height: 30px;
  }
  .card-writeUp-desc{
      font-size: 13px;
      line-height: 24px;
      height: 150px;
  }
  
  .card-btn{
      margin-top: 10px;
  }

  /* .event-cards {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
  } */

  .upcoming-event-cards {
      display: none;
  }

  .event-btn {
      font-size: 18px;
      line-height: 20px;
  }
  .event-card{
      width: 300px;
      /* height: 650px; */
  }
  
  .btn-box {
      display: flex;
      justify-content: space-around;
  }

  .date-box {
      width: 60px;
      height: 60px;
      left: 220px;
  }
  .dm{
      font-size: 15px;
      line-height: 20px;
  }

  .event-des {
      width: 210px;
      min-height: 300px;
  }

  .event-des-head {
      font-size: 18px;
      line-height: 22px;
  }

  .event-des-para {
      font-size: 12px;
      line-height: 15px;
  }

  .colleges{
      display: flex;
      flex-direction: column;
  }
  .college-name{
      text-align: center;
      font-size: 20px;
      line-height: 30px;
  }
 


  .college-head{
      font-size: 35px;
      line-height: 40px;
  }

  .event-btn{
      font-size: 14px;
      line-height: 13px;
      padding: 10px 15px;

  }

  

  .footer-terms {
      font-size: 10px;
  }
  .event-para {
      font-size: 25px;
  }
  

}

@media screen and  (max-width:365px){
  

  .date-box{
      left: 220px;
  }


  .card-writeUp-head {
      line-height: 20px;
      height: 70px;
      font-size: 13px;
      height: 55px;
      line-height: 18px;
  }
  
}

@media screen and  (max-width:350px){
  .date-box{
      left: 220px;
  }
  .main-img{
      width: 250px;
  } 

  
  .event-card{
      width: 250px;
  }
  .footer-terms {
      font-size: 8px;
  }
  
}




@media screen and  (max-width:330px){
  .card-writeUp-head{
      font-size: 18px;
      line-height: 25px;
  }



}


@media screen and  (max-width:330px){
  #intro p{
      width: 200px;
      text-align: left;
      font-size: 35px;
      line-height: 45px;
  }
  #main-para p{
      text-align: left;
      font-size: 15px;
      width: 200px
  }
  .main-img{
      width: 200px;
  }   

  button{
      width: 100px;
      height: 30px;
      font-size: 12px;
  }
  .card-heading .card-para{
      font-size: 30px;
      line-height: 45px;
  }

  .card {
      min-height: 250px;
      margin-top: 50px;
  }

  .card-content{
      bottom: 50px;
  }

  .card-img {
      width: 70px;
      height: 80px;

  }

  .card-writeUp-head {
      line-height: 20px;
      height: 70px;
      font-size: 13px;
      height: 55px;
      line-height: 18px;
  }

  .card-writeUp-desc {
      line-height: 15px;
      font-size: 8px;
      height: 100px;
  }

  .card-btn{
      margin-top: 10px;
      width: 100px;
      height: 25px;
      font-size: 10px;
  }

  /* .event-cards {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
  } */

  .event-card{
      width: 50px;
  }
  
  .event-para{
      font-size: 30px;
      line-height: 45px;
  }

  .college-head{
      font-size: 30px;
      line-height: 45px;
  }

  .event-btn{
      font-size: 10px;
      line-height: 13px;
      padding: 10px 15px;

  }

  .btn-box {
      display: flex;
      justify-content: space-around;
  }

  

  /* .event-des {
      width: 180px;
  } */

  .event-des-head {
      font-size: 18px;
      line-height: 22px;
  }

  .event-des-para {
      font-size: 12px;
      line-height: 15px;
  }

  .date-box {
      width: 50px;
      height: 50px;
      left: 230px;
      top: 30px
  }
  .dm{
      font-size: 8px;
      line-height: 15px;
  }

  .dm {
      font-size: 12px;
      line-height: 15px;
  }

  .twitter-box {
      width: 100px;
      height: 25px;
  }

  .twitter-logo {
      width: 20px;
      height: 20px;
  }

  .college-name{
      text-align: center;
      font-size: 20px;
      line-height: 30px;
  }

  .college-head{
      font-size: 30px;
      line-height: 40px;
  }

  

}

@media screen and  (max-width:300px){

  .event-card{
      width: 230px;
  }
  .event-des{
      width: 150px;
  }
  .date-box{
      left: 170px;
  }
  .event-para {
      font-size: 20-px;
  }
}

.main-partner-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
margin-right: 20px;
}

.companies {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 550px;
  position: absolute;
  /* bottom: 10px; */

}



.c-box {
  display: flex;
  justify-content: space-around;
  /* position: relative; */
  /* z-index: 1; */

}

.c-name {

  margin: auto;
  /* width: 144px; */
  /* height: 38px; */


  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;



  text-decoration: none;
  color: aquamarine;
  cursor: pointer;
  transition-property: text-decoration font-size;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

}

.c-name:hover {
  text-decoration: underline;
  font-size: 25px;

}

.c-img {
  width: 30px;
  height: 30px;
  margin: 0 20px;
}
.aptos-img{
  width: 40px;
  height: 40px;
}
.aptos-name{
  font-size: 22px;
}

.layer-box {
  margin: 30px 0;
}

.c-box-1 {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 50px;
}

.c-box-4 {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 50px;
}

.c-box-2 {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 50px;
  
}

.c-box-3 {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 50px;
}

.martian{
  position: relative;
  left: 20px
}
.movebit{
  position: relative;
  right: 20px;
}
.chingari{
  position: relative;
  left: 20px;
}
.kudomoney{
  position: relative;
  right: 20px;
}

@media screen and (max-width: 600px) {
  /* #partners-img{
      width: 450px;
      height: 450px;
      
  } */
  #partners-img{
      margin-top: 40px;
  }
  .companies{
      width: 500px;
  }
  /* .layer-box{
      margin: 25px;
  } */
  .c-img{
      width: 30px;
      height: 30px;
  }
  .aptos-img{
  width: 40px;
  height: 40px;
}
  .c-name{
      font-size: 15px;
      line-height: 30px;
  }
  .aptos-name{
      font-size: 17px;
  }

  .c-name:hover {
  text-decoration: underline;
  font-size: 20px;

}
}

@media screen and (max-width: 580px){
  .c-img{
      margin: 10px;
  }
  .layer-box{
      margin: 0px
  }
  .companies{
      height: 450px;
      overflow: hidden;
  }
  #partners-img{
      width: 360px;
      height: 360px;
      overflow: hidden;
      margin-top: 40px;
  }
  .c-box-1{
      bottom: 20px;
  }
  .martian{
      left: 0;
  }
  .movebit{
      right: 0;
  }
  .c-box-3{
      bottom: 70px;
  }
  .petra{
      position: relative;
      right: 20px;
  }
  .kanalabs{
      position: relative;
      left: 30px;
  }
  .c-box-4{
      bottom: 80px;
  }
  .kudomoney{
      right: 0;
  }
  .chingari{
      left: 20;
  }
}

@media screen and (max-width: 500px){
  .companies{
      width: 400px;
  }
  .petra{
      right: 40px;
  }
  .kanalabs{
      left: 40px;
  }
}


@media screen and (max-width: 450px){
  .companies{
      width: 380px;
  }
  .c-box-1{
      bottom: 10px;
  }

  .c-img{
      width: 28px;
      height: 28px;
  }
  .aptos-img{
  width: 35px;
  height: 35px;
}
  .c-name{
      font-size: 13px;
      line-height: 28px;
  }
  .aptos-name{
      font-size: 15px;
  }

  .c-name:hover {
  text-decoration: underline;
  font-size: 18px;

}

  

}

@media screen and (max-width: 400px){
  #partners-img{
      width: 300px;
      height: 300px;
      overflow: hidden;
      margin-top: 40px;
  }

  .companies{
      width: 300px;
  }
  .c-box-1{
      bottom: 0;
      top: 20px;
  }
  .c-box-2{
      bottom: 30px;
  }
  .c-box-4{
      bottom: 110px;
  }
  .petra{
      right: 40px;
  }
  .kanalabs{
      left: 26px;
  }
  .chingari{
      left: 5px;
      
  }
  .kudomoney{
      left: 15px;
  }
  .martian{
      left: -15px;
  }
}

@media screen and (max-width: 350px){
  #partners-img{
      width: 250px;
      height: 250px;
      overflow: hidden;
      margin-top: 40px;
  }
  .companies{
      width: 250px;
      
      
  }

  .c-img{
      width: 20px;
      height: 20px;
  }
  .aptos-img{
  width: 25px;
  height: 25px;
}
  .c-name{
      font-size: 10px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .c-name:hover {
  text-decoration: underline;
  font-size: 18px;

}
.c-box-1{
  top: 40px;
}
.c-box-2{
  bottom: 30px;
}
.petra{
  right: 30px;
}
.kanalabs{
  left: 20px;
}
.kudomoney{
  left: 0;
}

}

@media screen and (max-width: 300px){
  #partners-img{
      width: 200px;
      height: 200px;
      overflow: hidden;
      margin-top: 40px;
  }
  .c-box-1{
      top: 60px;
  }
  .c-box-2{
      bottom: 10px;
  }
  .c-box-4{
      bottom: 120px;
  }
}